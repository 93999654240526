/* General Styles */
.home-omrade2-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: white; /* Light accent */
  margin: 0 auto;
}

.home-omrade2-grid {
  display: grid;               /* Change to grid layout */
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 15px;                  /* Gap between items */
  max-width: 1200px;         /* Maximum width of the grid */
  width: 95%;                 /* Width of the grid relative to its container */
  margin: 0 auto;            /* Center the grid within its parent */
}

/* Link Styles */
.home-omrade2-item {
  text-decoration: none;
  color: #333; /* Text main color */
  padding: 10px; /* Adjusted padding */
  border-radius: 2px;
  position: relative;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15); /* Adds shadow on hover */

  width: 95%;
  background: linear-gradient(to right, #1A76C2 50%, #f0f4f8 50%);
  background-size: 201% 100%;
  background-position: right bottom;
  transition: background-position 0.3s ease, background-color 0.3s ease;
}

.home-omrade2-item:hover {
  background-position: left bottom;
  text-decoration: none;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5); /* Shading effect on hover */
  background-color: #1A76C2; /* Blue background on hover */
  color: white; /* Change text color to white on hover */
}

/* Container for each grid item */
.home-omrade2-container {
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: space-between;
  gap: 15px; /* Reduced gap between children */
}

/* Icon and Image */
.home-omrade2-icon {
  width: 60px; /* Adjust width if needed */
  color: #1A76C2; /* Accent color */
  transition: color 0.3s ease; /* Transition for color change */
}

.home-omrade2-item:hover .home-omrade2-icon
,.home-omrade2-item:hover home-omrade2-arrow {
  color: white; /* Change icon color to white on hover */
}


/* Text content */
.home-omrade2-content {
  flex: 1; /* Take remaining space */
  text-align: left; /* Align text to the left */
}

.home-omrade2-title {
  font-size: 1rem; /* Decreased font size */
  font-weight: bold;
}

/* Arrow styling */
.home-omrade2-arrow {
  font-size: 24px; /* Decreased arrow size */
  color: #1A76C2; /* Blue arrow */
  transition: color 0.3s ease; /* Transition for hover effect */
}


.home-omrade2-item:hover .home-omrade2-arrow {
  color: white; /* Change arrow color to yellow on hover */
}

/* Responsive layout */
@media (max-width: 1100px) {
  .home-omrade2-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on medium screens */
  }
}

@media (max-width: 800px) {
  .home-omrade2-grid {
    grid-template-columns: 1fr; /* 1 column on small screens */
    gap: 10px; /* Decrease gap on smaller screens */
  }

  .home-omrade2-item {
    padding: 10px; /* Adjusted padding for smaller screens */
  }
}
