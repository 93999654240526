.hi-hero-img-container {
    width: 100%;
    text-align: left; /* Keep the overall text alignment */
  }
  
  .hi-hero-img-hero {
    position: relative;
    height: 800px;
    top: 0px;
    background-size: cover;
    background-position: 50% 50%; /* Center the image horizontally and vertically */
    background-repeat: no-repeat;
    
  }

  
  /* Dark overlay added */
  .hi-hero-img-hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay, adjust opacity as needed */
    z-index: 1;
  }
  
  .hi-hero-img-hero-overlay {
    position: absolute;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Creates a soft shadow around the text */

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: left;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background for text block */
    padding: 20px;
    width: 95%;
    max-width: 1000px;
    z-index: 2; /* Ensures the overlay text is above the darkened background */
  }
  
  .hi-hero-img-title {
    font-size: 3rem;
    text-align: left;
    margin: 0;

    
  }
  .hi-hero-h2{
    text-align: left;
    margin: 0;
  }
  .hi-hero-p{
    text-align: left;
    margin: 0;


  }
  .hi-hero-img-hero-text {

  }

  /* Adjustments for screens up to 1200px */
  @media (max-width: 1200px) {
    .hi-hero-img-hero {
      top: 0;
    }
  }
  
  /* Responsive Design for screens up to 800px */
  @media (max-width: 800px) {
    .hi-hero-img-hero {
      height: 700px;

    }
  
    .hi-hero-img-title {
      font-size: 2rem;
    }
  
    .hi-hero-img-hero-text {
      font-size: 1.2rem;
    }
  }
  