.fokus-grid-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  justify-items: center; /* Centers items on uneven rows */
  padding: 20px;
}

/* Individual grid item */
.fokus-grid {
  position: relative;
  width: 280px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  color: rgb(255, 250, 245);
  text-align: center;
  padding: 20px;
  overflow: hidden; /* Prevents image overflow */
}
.fokus-grid-description p{font-size: 16px;}
@media screen and (max-width: 768px) {
  .fokus-grid-grid {
    padding: 5px;
  }
  .fokus-grid {
    position: relative;
    width: 260px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    color: rgb(255, 250, 245);
    text-align: center;
    padding: 20px;
    overflow: hidden; /* Prevents image overflow */
  }
}
/* Background image container */
.fokus-grid-image {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #1A76C2;
  width: 100%;
  height: 100%;
  z-index: -1; /* Moves the background image behind the text */
}

.fokus-grid-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the div */
  filter: brightness(65%);
}

/* Content inside the grid item */
.fokus-grid-content {
  position: relative; /* Ensures text stays above the background */
  z-index: 1;
}

/* Title styling */
.fokus-grid-title {
  margin-top: 0;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7); /* Adds a shadow behind text */

}

/* Description text */
.fokus-grid-description {
  text-align: left;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7); /* Adds a shadow behind text */

  color: rgb(255, 250, 245);
}
