.avtal-hero-container {
    display: flex;
    justify-content: space-evenly; /* Adjust spacing between left and right sections */
    align-items: center; /* Centers children vertically */
    padding: 40px;
    padding-top: 120px; /* Adjust padding as needed */
    background-color: #EAF1F7;

    position: relative; 
    z-index: 1; /* Ensure this is above the background */
    /* background-color: transparent; 
       If you want to see the background fully, remove or override any solid background color */
  }

  .avtal-hero-left {

      flex: 1; /* Make it flexible */
      max-width: 800px;
      text-align: left;
      margin: 0 auto; 
      display: flex;
      flex-direction: column;
      justify-content: center; /* Center vertically */
      align-items: center; /* Center horizontally if needed */
      height: 100%; /* Ensure it takes full height */
  
  }
  .avtal-info-paragraph{
    text-align: left;
    max-width: 800px;
    width: 95%;
    margin: 0px auto;
    margin-top: 60px;
  }
  .avtal-introv2-container h2{
    font-size: 32px;
  }
/*
    .avtal-introv2-container {
      padding: 40px;
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      margin-right: 20px;
      max-width: 800px;
      text-align: left;
      margin: 0 auto;
      position: relative; 
  }*/
  .avtal-herov2-below {
 display: none;

  }
  .avtal-hero-right {
    flex-shrink: 0; /* Prevent the right part from shrinking */
    max-width: 350px; /* Adjust the width of the right part */
    text-align: left;

  }
  .avtal-herov2-right {

      margin-top: -20px; /* Move it upwards by 20px */
      flex-shrink: 0; /* Prevent the right part from shrinking */
      max-width: 350px; /* Adjust the width of the right part */
      text-align: left;
      position: relative; 



  }
  
  .avtal-cta-button{
    display: block; /* Change to block to enable centering */
    margin: 0 auto; 
    padding: 15px 15px;
    border-radius: 50px;
    transition: 0.3s;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Shadow on hover */

    cursor: pointer;
    text-decoration: none;
    font-size:18px;
    font-weight:600;

    background-color: #1A76C2;
    border: 1px solid #1A76C2;

    color: white;
}

/*
.avtal-herov2-container {
  display: flex;
  justify-content: center; 
  align-items: center; 
  padding: 40px;
  margin-top: 50px;
  height: 450px;
  background-color: #EAF1F7;
}
*/
.avtal-buyv2-now-card {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align text in the card */
  padding: 15px;
  margin-top: -100px; /* Pull the card upwards */
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  min-width: 300px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative; /* Position relative to allow precise positioning */
  z-index: 2; /* Ensure it stays on top */
}

.avtal-introv2-container {
  display: flex;
  width: 95%;
  flex-direction: row;
  margin-top: 20px;
  margin-right: 20px; /* Add space between text and the card */
  max-width: 1440px;
  gap:20px;
  text-align: left;
  justify-content: space-between; /* Evenly distribute items with space between them */

  margin: 0 auto;
  position: relative; /* Ensures proper stacking context for child elements */
  z-index: 1; /* Position this behind the card */
}
.avtal-introv2-left{
  margin-top: 40px;
  margin-bottom: 80px;
  margin-left: 60px;
  max-width: 800px;

}
.avtal-herov2-container {
  display: flex;
  justify-content: center; /* Adjust spacing between left and right sections */
  align-items: center; /* Align items to the top */
  padding: 40px;
  margin-top: 50px;
  height: 650px;
  background-color: #EAF1F7;
  position: relative; /* Ensures proper stacking context */
}
.avtal-herov2-left {



}
.avtal-main-herov2-h1 {
  font-size: 2em; /* Adjust size as needed */
  color: white;
  font-weight: 600;

}

.avtal-main-herov2-p {
  font-size: 1.2em; /* Adjust size as needed */
  color: white;
  line-height: 1.5; /* Adjust line-height for readability */
}

.avtal-readmore-button {
  display: inline-flex; /* Ensures it only takes the necessary width */
  align-items: center;  /* Keeps text vertically aligned */
  justify-content: center; /* Ensures text is centered */
  padding: 12px 20px;  /* Slightly adjusted padding for better fit */
  border-radius: 50px;
  transition: 0.3s;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Shadow on hover */
  margin-top: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  position: relative; /* Fix bottom spacing */
  background-color: white;
  border: 1.5px solid #1A76C2;
  color: #1A76C2;
  white-space: nowrap; /* Prevents text from wrapping */
  max-width: max-content; /* Ensures it only takes as much space as needed */
}

.avtal-readmore-button:hover{
  background-color: #ff9d33    ;
  border: 1px solid #ff9d33;
  color: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4); /* Shadow on hover */
}
.avtal-cta-button:hover{
  background-color: #ff9d33    ;
  border: 1px solid #ff9d33;
  color: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4); /* Shadow on hover */

}
.avtal-readmore-link{
  cursor: pointer;
  text-decoration: none;
  font-size:18px;
  font-weight:600;
  color:white;
}
.avtal-readmore-link:hover{
  color:#ff9d33;

}
.avtal-cta-row{
  bottom:50px;
  margin-top: auto;
}

.avtal-readmore-btn{
    
    display: inline-block; /* Change to block to enable centering */
    padding: 15px 15px;
    border-radius: 50px;
    transition: 0.3s;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Shadow on hover */
    margin-top: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size:18px;
    font-weight:600;
 
    background-color: #1A76C2;

    margin-right: 15px;
  
    color: white;
  }
  .avtal-readmore-btn:hover{
    background-color: #ff9d33    ;
    border: 1px solid #ff9d33;
    color: white;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4); /* Shadow on hover */
  }


  .avtal-hero-main-img {
    width: 100px; /* Set width of the circular image */
    height: 100px; /* Set height to match width for circular image */
    border-radius: 50%; /* Make the image circular */
    object-fit: cover; /* Ensure the image covers the container */
    margin-top: 5px;
    margin-bottom: 10px; /* Space between image and text */
  }
  .avtal-herov2-main-img {
    width: 100px; /* Set width of the circular image */
    height: 100px; /* Set height to match width for circular image */
    border-radius: 50%; /* Make the image circular */
    object-fit: cover; /* Ensure the image covers the container */
    margin-top: 5px;
    margin-bottom: 10px; /* Space between image and text */
  }
  /*: #EAF1F7;ff9d33*/
  .avtal-buy-now-card {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align text in the card */
    padding: 15px;
    margin-top: 25px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    min-width: 320px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .avtal-pris-h2{
    margin: 0;
    text-align: center;

  }
  .avtal-pris{
    font-size: 25px;
    margin: 10px;
  }

  .avtal-pris-p{
    margin: 0;
    max-width: 90%;
    width: 90%;
  }
  .avtal-accent-text {
    color: #1A76C2; /* Accent color for the title */
  }
  
  .avtal-main-hero-h1 {
    padding: 0;
    margin-top: -20px;
    margin-bottom: 5px;
  }
  
  .avtal-main-hero-p {
    padding: 0;
    margin: 0;
    line-height: 1.5; /* Adjust line-height for readability */
  }
  


  .avtal-custom-list {
    list-style-type: none; /* Remove default bullets */
    padding: 0; /* Remove default padding */
    margin: 0; /* Remove default margin */
  }
  
  .avtal-custom-list li {
    position: relative; /* Positioning for the custom bullet */
    padding-left: 30px; /* Space for the custom bullet */
    margin-bottom: 10px; /* Space between items */
    text-align: left;
  }
  
  .avtal-custom-list li::before {
    content: '✔'; /* Unicode character for checkmark */
    color: #ff9d33; /* Red color for the checkmark */
    position: absolute; /* Absolute positioning */
    left: 0; /* Position the checkmark at the start of the list item */
    top: 0; /* Align with the top of the list item */
    font-size: 20px; /* Adjust size as needed */
  }
  

  .avtal-buy-active, .avtal-buy-inactive {
    display: inline-block;
    width: 150px; /* Set a fixed width */
    height: 40px; /* Set a fixed height */
    border-radius: 50px;
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.avtal-buy-active {
    background-color: #1A76C2;
    color: white;
    border: 1px solid #1A76C2;
}

.avtal-buy-inactive {
    color: #1A76C2;
    background-color: transparent;
    border: 1.5px solid #1A76C2;
}

.avtal-buy-active:hover, .avtal-buy-inactive:hover {
    background-color: #ff9d33;
    border: 1px solid #ff9d33;
    color: white;
}
.avtal-button-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Adjust as needed */
    gap: 5px; /* Space between buttons */
    justify-content: center; /* Center the buttons */
    
    margin-bottom: 10px; /* Optional: margin around the container */
}
.avtal-second-container {
    max-width: 1440px;
    width: 90%;
    gap: 100px;
    text-align: left;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    padding: 20px;
  }
  
  .avtal-second-column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .avtal-second-side-v2{
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    width: 95%;
    max-width: 800px;
    text-align: left;
    margin-top: 20px;

  }
  .avtal-second-side-v2-sub{
    display: flex;
    margin: 0 auto;
    flex-direction: row;
    width: 95%;
    max-width: 800px;
    margin-top: 20px;
  
  }
  .avtal-second-side {
    display: flex;
    flex-direction: column;
    width: 350px;
    flex-shrink: 0;
    margin-top: 20px;
    flex-basis: 350px;
  }
  .avtal-link{
    color: #d46e00;
    text-decoration: none;
  }
  

/* General styles */
.avtal-info-med-bild {
  display: flex;
  flex-direction: column;
  gap: 70px;
  margin: 0 auto;
  width: 100%;
}

/* Heading and Paragraph */
.avtal-info-heading,
.avtal-info-paragraph {
  text-align: left;
}

/* Image block styles */
.avtal-info-image-row {
  display: flex;

  flex-direction: row;
  gap: 20px;
}


.avtal-info-image-row.reverse {
  flex-direction: row-reverse; /* text left, image right */
  display: flex;

  gap: 20px;
}
.avtal-herov3-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  filter: brightness(75%) contrast(85%);
  z-index: 0; /* Put the background behind */
}
.avtal-info-image-container {
  flex: 1;
}

.avtal-info-image {
  width: 100%;
  height: auto;
  max-height: 450px;
  min-height: 250px;
  object-fit:scale-down;
}

.avtal-info-text-container {
  display: flex;             /* Enable Flexbox */
  flex-direction: column;    /* Ensure content stacks vertically */
  justify-content: center;   /* Vertically center the content */
  align-items: flex-start;   /* Align text to the left (or use center if needed) */
  flex: 1;                   /* Allow the container to grow and fill the parent */
  width: 100%;               /* Full width */
  height: 100%;              /* Full height */
  padding: 40px;             /* Add some padding for spacing */
  box-sizing: border-box;    /* Ensure padding doesn't affect dimensions */
}
.avtal-tips-paragraph p{
  margin: 0 auto;
  max-width: 800px;
  margin-bottom: 20px;
  text-align: left;
}
.avtal-info-caption {
  text-align: center;
  font-style: italic;
  margin-top: 10px;
}

/* Tips block styles */
.avtal-info-tips {
  display: flex;
  width: 95%;
  max-width: 1000px;
  margin: 0 auto;
  flex-direction: column;
  gap: 5px;
}
.avtal-tips-subblock p {
  text-align: left;
  margin: 0;
}
.avtal-tips-subblocks {
  display: flex;
  
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}
.avtal-subblock-heading{
  margin: 5px auto;
}
.avtal-tips-heading{
  margin:15px auto
}
.avtal-tips-subblock {
max-width: 450px;
min-width: 280px;

  padding: 10px;
  border-radius: 5px;
}

/* Accordion block styles */
.avtal-info-accordion {
  display: flex;
  width: 95%;
  margin: 0 auto;
  max-width: 1440px;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.avtal-accordion-left {
  flex: 1;
}
.avtal-accordion-left p {
text-align: left;}
.avtal-accordion-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.avtal-accordion-item {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.avtal-info-image-paragraph{
  text-align: left;
}
@media screen and (max-width: 1200px) {
  
  .avtal-cta-row{
    display: none;
  }
    .avtal-second-container{
        display: flex;

        flex-direction: column;}
        .avtal-second-side {
            margin-top: -60px;
            margin-bottom: 30px;
        width:95%;
        }
        
        .avtal-second-side-v2 {
            margin-top: -60px;
            margin-bottom: 30px;
        width:95%;
        }
        .avtal-hero-container{
            margin: 0 auto;
            flex-direction: column;
        }
        .avtal-hero-right {
margin: 0 auto;
        }

    }
@media screen and (max-width: 800px) {
    .avtal-buy-active, .avtal-buy-inactive {
        font-size: 16px;
        width: 120px; /* Adjust width for smaller screens */
        height: 45px; /* Adjust height for smaller screens */
    }

.avtal-herov2-container {

  height:450px;

}
.avtal-herov2-left {
  margin-top: 20px;

}

}
@media screen and (max-width: 1100px) {

  .avtal-info-image-row.reverse {
    flex-direction: column;
    width:90%;
    margin:0 auto;}
  .avtal-info-image-row {
  flex-direction: column;
  width:90%;
  margin:0 auto;
  }
  .avtal-accordion-heading{
    margin: 5px auto;
    text-align: left;
  }
  .avtal-info-accordion {
    flex-direction: column;
    width:90%;
    margin:0 auto;
    gap:5px;
    }
.avtal-herov2-right {
  display: none;

}
.avtal-bottom-button-container{
  width: 80%;
  margin-bottom: 20px;
  }
  .avtal-cta-button{
    width: 100%;
  }
.avtal-introv2-container {
flex-direction: column;
max-width: 800px;

}

.avtal-herov2-below {
  flex-shrink: 0; /* Prevent the right part from shrinking */
  max-width: 80%; /* Adjust the width of the right part */
  text-align: left;
  align-self: center;
  display: block;
  margin-bottom: 40px;

}
.avtal-herov2-main-img{
  width: 150px; /* Set width of the circular image */
    height: 150px; 
}
}
@media screen and (max-width: 1200px) and (min-width: 800px) {

.avtal-herov2-container {

  height: 450px;

}
.avtal-buyv2-now-card{
 
}
.avtal-herov2-right {
margin-top: 0px;
}
.avtal-herov2-left {
  margin-top: 20px;
  margin-left: 20px;

}
}
    @media screen and (min-width: 1200px) {
   
    .avtal-hero-container{    padding-top: 190px; /* Adjust padding as needed */
    }
 
}

