.desktop-footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: hsl(207, 76%, 43%);
    color: white;
}

.desktop-footer-sub {
    display: grid;
    width: 90%;
    align-self: center;
    margin-top: 30px;
    gap: 35px;
    grid-template-columns: repeat(4, 1fr); /* Default to 4 items per row */
}
.desktop-footer-sub2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center; /* Centers horizontally */
    width: 90%;
    align-self: center;
    
    margin: 0 auto;
    margin-top: 30px;
}


.desktop-footer-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
}

.desktop-footer-item {
    text-decoration: none;
    text-align: left;
    color: white;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 16px;
}

.desktop-footer-item2 {
    text-decoration: none;
    color: white;
    margin-top: 5px;
    margin-bottom: 5px;
}

.footer-logo {
    filter: invert(100%) sepia(0%) saturate(7437%) hue-rotate(312deg) brightness(102%) contrast(100%);
    flex: 1 1 25%; /* 1/4th of the width */
}

.desktop-footer-sub2 p {
    flex: 1 1 75%; /* 3/4th of the width */
    margin: 0 15px; /* Optional spacing */
    text-align: left;
}

/* Below 800px: switch to column layout */
@media only screen and (max-width: 800px) {
    .desktop-footer-sub2 {
        flex-direction: column;
        text-align: center; /* Center-align text in column layout */
    }

    /* Ensure full width for both elements in column layout */
    .footer-logo,
    .desktop-footer-sub2 p {
        flex: 1 1 100%;
        max-width: 100%;
    }

    .footer-logo {
        margin-bottom: 15px; /* Add space below logo in column layout */
    }
}
/* 2 items per row between 900px and 1200px */
@media only screen and (max-width: 1200px) {
    .desktop-footer-sub {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* 1 item per row below 900px */
@media only screen and (max-width: 700px) {
    .desktop-footer-sub {
        grid-template-columns: 1fr;
    }
}
