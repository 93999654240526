/* General Styles */
.omraden2-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: white; /* Light accent */
  }
  
  .omraden2-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    gap: 20px;
    max-width: 1200px;
    width: 95%;
  }
  
  /* Link Styles */
  .omrade-link {
    text-decoration: none;
    flex-basis: 45%;
    width: 80%;
    color: #333; /* Text main color */
    padding: 20px;
    border-radius: 2px;
    position: relative;
    background: linear-gradient(to right, #1A76C2 50%, #f0f4f8 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.3s ease, background-color 0.3s ease;
  }
  
  .omrade-link:hover {
    background-position: left bottom;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5); /* Shading effect on hover */

    background-color: #1A76C2; /* Blue background on hover */
    color: white; /* Change text color to white on hover */
  }
  .omrade-link:hover .omrade-icon {
    color: white; /* Change icon color to white on hover */
  }
  .omrade-link:hover .omrade-text,
  .omrade-link:hover .omrade-arrow {
    color: white; /* Change text color to white on hover */
  }
  /* Container for each grid item */
  .omrade-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
  }
  
  /* Icon and Image */
  .omrade-icon-container {
    width: 60px;
  }
  
  .omrade-icon {
    color: #1A76C2; /* Accent color */
  }
  

  
  /* Text content */
  .omrade-content {
    text-align: left;
  }
  
  .omrade-title {
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .omrade-text {
    font-size: 1rem;
    color: #555;
  }
  
 
  /* Arrow styling */
  .omrade-arrow {
    align-self: center;
    font-size: 30px;
    color: #1A76C2; /* Blue arrow */
    transition: color 0.3s ease;
  }


  /* Responsive layout */
  @media (max-width: 900px) {
    .omrade-link {
      flex-basis: 100%;
      width: 100%;
    }
  
    .omrade-container {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  