.aboutus-container {
    width: 100%;
    text-align: left; /* Keep the overall text alignment */
  }
  
  .aboutus-hero {
    position: relative;
    height: 400px;
    top:140px;
    margin-bottom: 140px;
    background-size: cover;
    background-position: center;
  }
  .aboutus-ord{
    background-color:#EAF1F7 ;
  }
  .aboutus-hero-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    padding: 20px;
    width: 95%;
    max-width: 1200px;
  }
  
  .aboutus-title {
    font-size: 3rem;
  }
  
  .aboutus-hero-text {
    font-size: 1.5rem;
  }
 
  .about-us-text-width{    width: 95%;
    max-width: 800px;}
  .aboutus-overview, 
  .aboutus-cl, 
  .aboutus-la {

    margin: 0 auto;
    padding: 20px;
  }
  
  /* CL Section Styles */
  .aboutus-cl {
    background-color: #1A76C2; /* Blue background */
    color: white; /* White text color */
    padding: 30px 20px; /* Extra padding for readability */
  }
  .about-us-h3{
    text-align: left;
  }
  .aboutus-cl h2, 
  .aboutus-cl-item h3 {
    color: white; /* Ensure titles are white as well */
  }
  
  .aboutus-cl-item {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* horizontal centering */
    align-items: center; /* vertical centering */
  
  }
  
  /* Make quotes stand out */
  .blockquote {
    font-style: italic;
    font-size: 1.2rem;
    padding: 15px;
    font-weight: 600;
    margin: 20px auto;
    color: #1A76C2;
    text-align: center; /* Center the quote text */
    width: 90%; /* Restrict the width */
    max-width: 1000px; /* Add a max width for consistency */
    border-radius: 5px; /* Slight rounding of corners */
  }
  
  /* Ensure the quotes in the LA section also stand out */
  .blockquote-y {    color: white;

  }

  .aboutus-la-item, 
  .blockquote {
    margin: 10px 0;
  }
  
  .aboutus-hero-section {
    display: flex;
    justify-content: space-around; /* Space between the images */
    align-items: center; /* Align items vertically in the center */
    margin: 50px auto; /* Center the row in the page */
    width: 80%; /* Adjust width of the row */
  }
  
  .aboutus-hero-item {
    text-align: center; /* Center the text and image */
  }
  
  .aboutus-circle {
    width: 150px;               /* Fixed width */
    height: 120px;              /* Height to crop 20% of the image bottom */
    border-radius: 50%;         /* Makes the image circular */
    object-fit: cover;          /* Ensures the image covers the circle while maintaining aspect ratio */
    object-position: top;       /* Align image to the top, cropping bottom 20% */
    margin-bottom: 10px;        /* Space between image and name */
}
  .about-us-text-width {
    width: 95%;
    max-width: 800px;
    margin: 0 auto; /* Centers the section container */
    text-align: left; /* Aligns text to the left */
}
.network-img {
    display: block;
    max-width: 80%; /* Ensures image doesn't fill the entire width */
    height: auto; /* Maintains the aspect ratio */
    margin: 0 auto; /* Centers the image */
    object-fit: contain; /* Ensures the image fits well without distortion */
  }
/* Ensure the sections have the same text alignment logic */
.aboutus-overview, 
.aboutus-la {
    width: 95%;
    max-width: 800px;
    margin: 0 auto; /* Centers the parent container */
    padding: 20px;
    text-align: left; /* Text inside is left-aligned */
}

  .aboutus-name {
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  @media (max-width: 1200px) {

    .aboutus-hero {
    top:80px;margin-bottom: 80px;
    }
}
  /* Responsive Design */
  @media (max-width: 800px) {
    .aboutus-hero {
      height: 300px;
    }
    .aboutus-hero {
    
    }
  
    .aboutus-title {
      font-size: 2rem;
    }
  
    .aboutus-hero-text {
      font-size: 1.2rem;
    }
  
    .blockquote {
      width: 90%; /* Make quotes wider on smaller screens */
    }
  }
  