.form-container{display: flex;
flex-direction: column;
padding: 40px 40px;
border-radius: 5px;

max-width: 700px;
gap: 20px;
background-color: #F7F0EA;
align-self: center;
justify-self: center;
margin: 0 auto;
}

@media (max-width: 800px) {
    .form-container{padding: 40px 5px;
        border-radius: 0px;
    }
}


@media (max-width: 800px) {
    .form-container{
    min-height: 900px;

    }
}


@media (max-width: 600px) {
    .form-container{
    min-height: 1000px;

    }
}



@media (max-width: 500px) {
    .form-container{
    min-height: 1100px;

    }
}