.main-hero {
    display:  flex;
    flex:1;
    flex-direction:  column;
    align-items:  center; 
  }
  .main-hero-h1{
    text-align: left; max-width:90%;margin-bottom:0
  }
  .main-hero-p{
    text-align: left;margin-top:10px; max-width:90%;
  }
  .main-hero-div{margin-top:20px;margin-bottom:50px; width:100%; display:flex;flex-direction:column; justify-content:center}
  

.main-hero-right-col-img{
    width: 350px;
    height: 450px;
    margin-bottom:50px;
    border-width: 4px;
    border-style: solid;

}
.main-hero-mobile-container {
    margin-bottom: 50px;
    margin-top: 50px;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
  
  .main-hero-mobile-content-wrapper {
    display: flex;
    width: 90%;
  }
  
  .main-hero-mobile-h1 {
    text-align: left;
    max-width: 90%;
    margin-bottom: 0;
  }
  
  .main-hero-mobile-p {
    text-align: left;
    max-width: 90%;
  }
  
  .main-hero-second{
    display:flex; flex-direction:row; max-width:1440px;    justify-content:  space-evenly  
  }
  @media (max-width: 850px) {
    .main-hero-mobile-container {
      flex-direction: column;
    }
    .main-hero-right-col-img{
      width: 280px;
      height: 360px;
  }}
  