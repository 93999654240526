
.pricing-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
  }
  
  .pricing-card {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 350px;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .pricing-list-container{
    padding:10px 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .pricing-banner {
    background-color: #2563eb;
    color: white;
    padding: 1rem;
    border-radius: 8px 8px 0 0;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .pricing-title {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .pricing-subtitle {
    font-size: 1rem;
    margin-top: 0.25rem;
  }
  
  .pricing-description {
    color: #374151;
    font-size: 1rem;
    margin-bottom: 1.5rem;
    
  }
  
  .pricing-features {
    margin-bottom: 1rem;
    color: #4b5563;
  }
  
  .pricing-feature {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    border-top: 1px solid rgba(0, 0, 0, 0.323);
  }
  
  .pricing-button {
    background: #2563eb;
    color: white;
    font-weight: 600;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 80%;
    margin-bottom: 25px;
    align-self: center;
  }
  
  .pricing-button:hover {
    background: #1d4ed8;
  }
  
  @media (max-width: 768px) {
    .pricing-container {
      flex-direction: column;
    }
  }
  