.avtallista-tjanster{
    text-align: left;
    width: 95%;
    max-width: 1400px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-bottom: 50px;
}

.avtallista-tjanster h2{
    margin-bottom: 0;
}
.avtallista-tjanster p{
    margin-top: 5px;
}

.avtallista-tjanster-div{
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 equal-width columns */

    gap: 5px;
}

.avtallista-tjanster-div-child{
    all: unset;
    background-color: #EAF1F7;
    padding: 8px 16px;
    transition: 0.3s;
    cursor: pointer;
    flex: 1 1 200px; /* Adjust the 200px to the minimum width you want */
    box-sizing: border-box; 
    display: flex;
    flex-direction: column; /* Make sure items stack vertically */
    justify-content: space-between; /* Pushes the link to the bottom */

}
.avtallista-tjanster-div-child:hover{
    text-decoration: none;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Shadow on hover */    
    }
.avtallista-tjanster-filter {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Responsive columns with minimum width */
    gap: 5px;
    margin-bottom: 10px;

}
.avtalslista-link {
    margin-top: auto; /* Push link to the bottom of the div */
    text-align: right; /* Aligns the text to the right */
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 18px;
    color:#d46e00;
    text-decoration: none;
}
.avtallista-tjanster-filter-child:hover{
    color: white;
    border:2px solid #ff9d33 ;
    background-color: #ff9d33;
    font-weight: 600;
}
.avtallista-tjanster-filter-child {
    background-color: #EAF1F7;
    border-width: 2px;
    padding: 4px 4px; /* Adjust padding as needed */
    border-radius: 200px;
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    align-items: center; /* Center text vertically */
    justify-content: center; /* Center text horizontally */
    min-height: 40px; /* Ensure minimum height for the boxes */
    text-align: center;
}
.avtallista-tjanster-filter-child-selected {
    background-color: #1A76C2;
    color:white;
    border-width: 2px;
    font-weight: 600;

    padding: 4px 4px; /* Adjust padding as needed */
    border-radius: 200px;
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    align-items: center; /* Center text vertically */
    justify-content: center; /* Center text horizontally */
    min-height: 40px; /* Ensure minimum height for the boxes */
    text-align: center;
}
.avtallista-tjanster-filter-child p {
    margin: 0;
    font-size: 15px;
}
.avtallista-tjanster-filter-child-selected p {
        margin: 0;
        font-size: 15px;
    }


@media only screen and (max-width: 900px)and (min-width:601px) {
    .avtallista-tjanster-div{
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* 4 equal-width columns */
    
        gap: 5px;
    }
}

@media only screen and (max-width: 600px) {
        .avtallista-tjanster-div{
            display: grid;
            grid-template-columns: repeat(1, 1fr); /* 4 equal-width columns */
        
            gap: 5px;
        }
    }
