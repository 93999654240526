.priser-container{
    width: 100%;
    justify-self: start;
    justify-content: center; 
    align-self: center;
    padding: 150px 20px 50px 20px;
    gap: 0;
    display: flex;

    text-align: start;
    padding-bottom:50px;
    padding-top:200px;
   
    min-height: 200px; 
   
}

.priser-container h2{ padding: 0; margin: 0;}
.priser-container p{ padding: 10px; margin: 0;}

.priser-sub-container{
    max-width: 1440px;
    display: flex;
    width: 95%;
    
    gap:75px;
    flex-direction: row;
    justify-content: space-evenly; 

    align-items: center; /* Center body content horizontally */
    margin: 0;
    padding: 0;
    
}

.priser-flex1{/*
    flex:1; height: 100%; align-self: stretch; display: flex;flex-direction: column;*/
    width: 100%; /* Use full width to allow centering inside */
    max-width: 1200px; /* Maximum width for the content area */
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    
    
}


.right-bottom{justify-self: end; align-self: end;text-align: center;font-weight: 600;}
.priser-order {
    background-color: #1A76C2;
    color: white;
    text-align: center;
    padding: 8px 16px;
    border-radius: 22px;
    font-weight: 500;
    font-size: 16px;
    text-decoration: none;
    border: none; /* Add this if you want to remove the default button border */
    cursor: pointer; /* Add this to change the cursor to a pointer */
}
.priser-none{
    font-weight: normal;
}
.priser-option-desktop{
    margin-top: 20px;
    background-color: white;
    padding: 15px;
    display: flex;gap:0;
    flex-direction: column;
    text-align: left;
    text-decoration: none;
    border: none; /* Add this if you want to remove the default button border */
    cursor: pointer; /* Add this to change the cursor to a pointer */
}
.priser-option-desktop-selected{
    border: 2px solid #1A76C2;
    margin-top: 20px;
    background-color: white;
    padding: 15px;
    display: flex;
    gap:0;
    flex-direction: column;
    text-align: left;
    text-decoration: none;
    cursor: pointer; /* Add this to change the cursor to a pointer */
}
.priser-order-selected {
    border:2px solid #1A76C2;
    background-color: white;
    color: #1A76C2;
    text-align: center;
    padding: 8px 16px;
    border-radius: 22px;
    font-weight: 500;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer; /* Add this to change the cursor to a pointer */
}
.prister-flex{display: flex;}
.priser-option-phone{display: none;}
@media only screen and (min-width: 940px) and (max-width: 1140px) {
    .priser-sub-container{
gap: 40px;}}/* Hiding phone version on desktop */
@media (min-width: 940px) {
    .priser-option-phone,
    .priser-option-phone-selected {
        display: none; /* Hide mobile version */
    }
}

/* Mobile & Tablet Layout */
@media only screen and (max-width: 939px) {
    .priser-container {
        padding: 60px auto;
        margin-left: -20px;
    }

    .priser-sub-container {
        flex-direction: column;
        margin-top: -40px;
        gap: 0;
        width: 90%;
    }

    /* Hide desktop cards on mobile */
    .priser-option-desktop {
        display: none;
    }

    /* Slightly taller cards and more space on tablet */
    .pris-option-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(2fr));
        gap: 20px; /* More space between cards */
        max-width: 100%;
    }

    /* Tablet card styles - taller */
    .priser-option-phone, 
    .priser-option-phone-selected {
        background-color: white;
        padding: 25px; /* More padding */
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        text-align: left;
        text-decoration: none;
        cursor: pointer;
        min-height: 230px; /* Slightly taller */
        justify-content: space-between;
        box-sizing: border-box;
        border: 2px solid white;
    }

    /* Selected card on tablet */
    .priser-option-phone-selected {
        border: 2px solid #1A76C2;
    }
}

/* Stack items in one column on mobile */
@media (max-width: 600px) {
    .pris-option-container {
        grid-template-columns: 1fr;
    }

    /* Keep normal height for smaller screens */
    .priser-option-phone, 
    .priser-option-phone-selected {
        min-height: 200px;
    }
}
