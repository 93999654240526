.tjanst-overview{
  width: 98%;
  display: flex; justify-content: center;
max-width: 1200px;
margin: 10px auto; /* Centers the container itself within its parent */

}
.tjanst-form{
    display: flex; justify-content: center; margin: 50px 0;
}
.tjanst-item-container{  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap:10px;
  max-width: 90%;
  margin: 10px auto;

}
.tjanst-item{
  display: flex;
   flex-direction: row;
   text-decoration: none;
   padding:0 10px;
   width: 300px;
justify-content: space-between;
background-color: #F5F5F5;
color: black;}
.tjanst-item:hover{
  color: white;
  background-color: #1A76C2;
}
.tjanst-content-div{
  width: 90%; max-width: 800px; margin: auto;text-align: left;
}
.tjanst-content-div-subdiv p{
  margin-bottom: 30px;
  margin-top: 0;
}

 
.tjanst-h1{
    margin-top: 0;
    text-align: left;
    margin-bottom: 0;
}
@media (max-width: 735px) {
 

    .tjanst-overview {

        min-height: 245px;
    }
    .tjanst-item-container{
      gap:5px
    }
    .tjanst-item{
    width: 90%;
    }
}