.cookie-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.cookie-checkbox-container{
  display:flex;
  gap:30px;
}
.cookie-checkbox{
  width: 40px; 
  height: 40px;
}
.cookie-modal-dialog {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Drop shadow effect */
  width: 80%;
  
  max-width: 800px;
  max-height: 90%;
  overflow-y: scroll;
}

.cookie-modal-content {
  padding: 20px;
}

.cookie-modal-header {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.cookie-modal-title {
  margin: 0;
  font-size: 20px;
}

.cookie-modal-footer {
  display: flex;
  
  justify-content: flex-end;
  padding-top: 10px;
  gap:5px
}

.button-spacing {
  margin-left: 0px;
}

.button {
  padding: 6px 12px;
  background-color: #007bff; /* Adjust as needed */
  color: #fff; /* Adjust as needed */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #0056b3; /* Adjust as needed */
}


@media screen and (max-width: 300px){
  .cookie-modal-footer {

  flex-wrap: wrap;
  }
}