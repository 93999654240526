.oneline-button{
    padding: 8px 16px;
    color:white;
    background-color:#1A76C2;
    width: 80px;
    height: 100px;
}
.oneline-one-link-btn{
    display: inline-block;
    padding: 15px 15px;
    background-color: #1A76C2;
    border: 1px solid #ccc;
    border-radius: 50px;
    cursor: pointer;
    text-decoration: none;
    color: white;
    font-size:18px;
    
    font-weight:500;
}
.oneline-two-link-btn{
    display: inline-block;
    padding: 15px 15px;
    border-radius: 50px;
    transition: 0.3s;
    transition: box-shadow 0.3s ease; /* Smooth transition for shadow */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25); /* Shadow on hover */

    cursor: pointer;
    text-decoration: none;
    font-size:18px;
    font-weight:500;
}
.oneline-active-link-btn-color{
    background-color: #1A76C2;
    border: 1.5px solid #1A76C2;

    color: white;
}

  
.oneline-inactive-link-btn-color{
    color: #1A76C2;
    background-color: transparent;

    border: 1.5px solid #1A76C2;
}
.oneline-two-link-btn:hover{
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5); /* Shadow on hover */

    background-color: #ff9d33    ;
    border: 1.5px solid #ff9d33;
    color: white;
    border-color: #ff9d33;
}

.oneline-contact-two-cta{
display: flex;
flex-wrap: wrap;

gap:10px;
flex-direction: row;
margin-left: auto;  /* Adding this to center horizontally */
margin-right: auto; /* Adding this to center horizontally */


}
.oneline-sub-div{
    display:flex; flex-direction:column; margin:0 auto;  padding-top:20px;justify-content: center;
}
.white-style {
    color: white;
    border: 1.5px solid white;   

  }
  
  .blue-style {
    color: #1A76C2;
    background-color: transparent;

    border: 1.5px solid #1A76C2;   
  }
.oneline-main-div{ text-align: center; display: flex; flex-direction: column; justify-content: center; }
@media screen and (max-width: 800px) {
 


.oneline-two-link-btn{
    padding: 15px 15px;
    font-size:16px;
    font-weight:500px;
}}