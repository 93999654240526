.newsletter-form-container {
    padding: 10px;
    max-width: 600px;
    width: 95%;
    margin: 0px auto;
    border-radius: 10px;
  }

  .newsletter-toggle-button {
    background-color: transparent;
    border: 1px solid currentColor;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  .newsletterform-row{
    display: flex;
    flex-direction: row;
    gap:5px
  }
.newsletter-h {
    text-align: center;
  }
  .newsletter-p {
    text-align: left;
  }
  .newsletter-form-input {
    display: block;
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  .newsletter-form-button {
    background-color: white;
    color: #1A76C2;
    padding: 4px 16px;
    border: none;
    height: 40px;
    margin: auto;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;

  }
  .newsletter-form-button:hover {
    background-color: #ff9d33;
    color: white;
  }