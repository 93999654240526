.header-navbtn{color:white;
font-weight:500;
text-decoration:none;
height: 100%;
display:flex;
padding:0 32px;
justify-content: 'center'; 
height:100%;
align-items: center;
text-align:center;
}