
.App {
  text-align: center; 
  font-family: "Montserrat", "Lato", sans-serif;
width:100%; overflow-X:clip;
padding:0;
margin: 0;
}
.p-max-width{
  width: 95%;
  margin: 0 auto;
  max-width: 1200px;
}
p{line-height: 25px;}

.center-text{text-align: center;}
.left-text{text-align: left;}
@media only screen and (min-width: 480px) and (max-width:720px){
  p {font-size: 17px;}
}
@media only screen and (max-width: 480px){
  p {font-size: 16px;}
}
@media only screen and (max-width: 720px){
  p {font-size: 19px;}
}
body{
  background-color: hsl(31.2, 100%, 98%);
  margin:0;
}
.root{
  padding:0;
  margin: 0;
}