.accordion-arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    cursor: pointer; /* Makes it clickable */
    transition: transform 0.3s ease; /* Smooth transition */
    margin-left: 30px;
}
  .dangacc-right{
    text-align: right;
  }
  .accordion-right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  @media screen and (max-width: 768px) {
    .omrade-herov3-container {
      height: auto; /* Allows container to adjust dynamically */
      padding: 20px; /* Reduces padding for better spacing */
    }
  
    .avtal-herov3-left {
      margin-top: 30px;
      margin-bottom: 10px;
      padding: 0 10px; /* Adds a little padding for smaller screens */
    }
  
    .omrade-herov3-background {
      background-size: cover;
      background-position: center;
    }
  }
  
  .accordion-left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  .accordion-question-left{text-align: left;}
  .accordion-question-left-col {
    display: flex;
    cursor: pointer;
    text-align: left;

    flex-direction: row;
    justify-content: space-between;  
  }

  .accordion-up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  
  .accordion-down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  .accordion-question{
    font-weight: 500;

  }
  .dang-link{
    text-decoration: none;

    font-size: 17px;
    color:#1A76C2;
    align-self:flex-end; justify-self:flex-end; padding:8px 16px;
            margin-bottom:10px; border:2px solid #1A76C2;
            border-radius: 20px;
            transition: 0.3s;
          }
  .dang-link:hover{
    text-decoration: none;
    color:#ff9d33;
    border-color: #ff9d33;
  }
  .accordion-div{
    
    max-width: 500px;
    text-align: center;
    width: 95%;
    margin: 0 auto;
    
  }
  .accordion-div-col{
    
    max-width: 800px;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    border-bottom: 1px solid gray;

    
  }
  .accordion-p{
    text-align: left;
  }
  .accordion{
    margin-bottom: 50px;
    margin-top: 20px;
  }