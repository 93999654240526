.sts-accent-text{
    color: #1A76C2;
}

.sts-cta-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 items in a single row on desktop */
    gap: 20px; /* Gap between the grid items */
    background-color: rgb(245,245 , 245);
    padding: 20px;
    align-items: center; /* Align items vertically */
    justify-items: center; /* Align items horizontally */
}
.sts-cta-line{
    height: 1px;
    width: 80%;
    margin: 0 auto;
    
    border-bottom: 1px solid #1A76C2;

}
.margin-zeroish{
    margin-top: 0;
    margin-bottom: 5px;
}
.sts-tjanster-h2{margin-bottom: 0;}
.sts-tjanster-p{margin-bottom: 35px;}

.sts-cta-line-container{
    background-color: rgb(245,245 , 245);
}
.sts-cta-button{
    display: inline-block;
    width: 150px;
    height: 50px;
    padding: 0;
    border-radius: 0; /* Make it perfectly round */
    transition: 0.3s;
    
    cursor: pointer;
    text-decoration: none;
    font-size:18px;
    font-weight:600;
    
    background-color: #ff9d33;
    border: 1px solid #ff9d33;
    
    color: white;
    text-align: center;
    line-height: 50px; /* Center the text vertically */
    
    position: relative;
    margin-left: auto; /* Pushes the button to the right in flexbox */
}

.sts-cta-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Responsive grid */
    gap: 20px; /* Space between cards */
  }
  
  .sts-cta-card {
    background-color: #EAF1F7; /* Light blue */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Box shadow */
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    min-height: 150px; /* Ensure cards have the same height */
  }
  
  .sts-cta-card p {
    margin: 0; /* Remove margin from paragraphs */
  }
  
.sts-tjanster{
    text-align: left;
    width: 95%;
    max-width: 1400px;
    display: flex;
    flex-direction: column;
    margin: 50px auto;
}
.sts-tjanster-div{
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 equal-width columns */

    gap: 5px;
}
.sts-tjanster-div-child{
    background-color: #EAF1F7;
    padding: 8px 16px;
    flex: 1 1 200px; /* Adjust the 200px to the minimum width you want */
    box-sizing: border-box; 
    display: flex;
    flex-direction: column; /* Make sure items stack vertically */
    justify-content: space-between; /* Pushes the link to the bottom */

}

.sts-link {
    margin-top: auto; /* Push link to the bottom of the div */
    text-align: right; /* Aligns the text to the right */
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 18px;
    color:#d46e00;
    text-decoration: none;
}

.sts-cta-button:hover{
  background-color: white    ;
  border: 1px solid #ff9d33;
  color:#ff9d33;
  
}
/* Media query for 2x2 grid on smaller screens */
@media (max-width: 800px) {
    .sts-cta-container {
        grid-template-columns: repeat(2, 1fr); /* 2 items per row */
    }
    .sts-tjanster-div {
        grid-template-columns: repeat(2, 1fr); /* 2 items per row */

}
}


/* Media query for single column layout on very small screens */
@media (max-width: 500px) {
    .sts-cta-container {
        grid-template-columns: 1fr; /* Single column layout */
    }
    .sts-tjanster-div {
        grid-template-columns: 1fr; /* Single column layout */

    }
}