.office-locations {
    display: flex;
    flex-direction: row;
    align-items: flex-start; /* Align items to the start of the container vertically */
    padding: 20px;
    justify-content: center;
    font-family: Arial, sans-serif;
    margin: 0 auto;
    width: 95%;
    max-width: 1200px;
    text-align: left;
  }
  
  .map-image {
    width: 100%; /* Make the image responsive, taking up full width of its container */
    max-width: 200px; /* Ensure the image doesn't exceed 200px in width */
    height: auto; /* Maintain aspect ratio */
    display: block; /* Remove any spacing below the image */
  }
  
  /* Optional: Add responsive behavior for different screen sizes */
  @media (max-width: 600px) {
    .map-image {
     max-width: 150px;; /* On smaller screens, let the image take full container width */
    }
  }
  .office-header {
    text-align: left;
    max-width: 800px;
  }
  
  .office-header h4 {
    font-size: 0.9em;
    font-weight: bold;
    color: #333;
  }
  
  .office-header h1 {
    font-size: 2em;
    font-weight: bold;
    color: #000;
  }
  
  .description {
    font-size: 1em;
    color: #666;
    margin-top: 10px;
  }
  
  .office-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 800px;
    margin-top: 20px;
  }
  
  .head-office,
  .regional-spaces {
    width: 100%;
  }
  
  .head-office h3,
  .regional-spaces h3 {
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  .map-row{
    display: flex;
    flex-direction: row;
  }
  .head-office p,
  .regional-spaces .location {
    font-size: 1em;
    color: #333;
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
  }
  
  .location-icon {
    color: #007bff;
    margin-right: 5px;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  .regional-spaces .locations {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px;
  }
  
  .map-container {
    width: 100%;
    max-width: 800px;
    height: 300px;
    margin-top: 20px;
  }
  @media (max-width:600px) {
.office-locations{
  flex-direction: column-reverse;
}
.map-image{
  margin: 50px auto 0px auto;

}
  }
