.omraden3-dynamic-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns of equal width */
    gap: 24px; /* Space between grid items */
    max-width: 1200px; /* Maximum width for the entire grid */
    margin: 0 auto; /* Center the grid */
}

.omraden3-omrade-link {
    text-decoration: none;
    color: inherit;
    width: 100%;
}

.omraden3-omrade-container {
    display: grid;
    grid-template-columns: 1fr 1fr 50px; /* 1fr for image, 1fr for content, fixed width for arrow */
    gap: 24px;
    align-items: center;
    padding: 0;
    border: 2px solid transparent; /* Default transparent border */
    border-radius: 0; /* Border radius on the container */
    background-color: #fff;
    transition: box-shadow 0.3s ease, border-color 0.3s ease;
    max-width: 1200px;
    margin: 0 auto; /* Center each child container */
}

.omraden3-omrade-container:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-color: #ffeb3b; /* Yellow border on hover */
}

.omraden3-omrade-image {
    width: 100%;

    height: 100%;
    object-fit: cover; /* Ensure image fills the parent while keeping aspect ratio */
    border-radius: 0; /* No border radius on the image */
    grid-column: 1 / 2; /* Ensure the image takes the left column */
    display: block;
}

.omraden3-omrade-content {
    display: flex;
    flex-direction: column;
    text-align: left; /* Left align text */
    grid-column: 2 / 3; /* Ensure content takes the second column */
}

.omraden3-omrade-title {
    font-size: 1.5rem;
    margin: 0;
    font-weight: bold;
}

.omraden3-omrade-text {
    margin: 8px 0 0;
    font-size: 1rem;
    color: #555;
}

/* Arrow styling */
.omraden3-omrade-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%; /* Circular shape */
    background-color: #007bff; /* Blue background */
    color: #fff; /* White arrow */
    font-size: 1.5rem;
    transition: background-color 0.3s ease, border-color 0.3s ease; /* Transition for hover effect */
    grid-column: 3 / 4; /* Ensure the arrow is in the third column */
}

.omraden3-omrade-container:hover .omraden3-omrade-arrow {
    background-color: #ffeb3b; /* Change arrow background to yellow on hover */
    color: #007bff; /* Change arrow color to blue when hovered */
}

/* Responsive layout for mobile devices */
@media (max-width: 800px) {
    .omraden3-omrade-container {
        grid-template-columns: 1fr; /* Switch to a single column on mobile */
        text-align: left;
    }

    .omraden3-omrade-image {
        width: 100%; /* Ensure image takes full width on mobile */
        height: auto; /* Maintain the aspect ratio */
    }

    .omraden3-omrade-content {
        margin-top: 16px; /* Add margin between image and content on mobile */
    }

    .omraden3-omrade-arrow {
        margin-top: 16px; /* Separate the arrow on mobile */
    }
}
