.formlight-container{display: flex;
    flex-direction: column;
    border-radius: 5px;
    
    flex:1;
    max-width: 500px;
    gap: 20px;
    background-color: transparent;
    align-self: center;
    justify-self: center;
    margin: 0 auto;
    }
    .formlight-btn {
        all: unset;
        padding: 12px 24px;
        background-color: #1A76C2;
        color: white;
        border: none;
        border-radius: 20px;
        cursor: pointer;
        margin-top: 10px;
        font-size: 16px;
        font-weight: 400;
        text-decoration: none;
        transition: background-color 0.3s ease;
        text-align: center;
      }
      
      /* Hover effect */
      .formlight-btn:hover {
        background-color: #ff9d33;
      }
    
    @media (max-width: 800px) {
        .form-container{
            border-radius: 0px;
        }
    }
    
    