.hero-main {

  width: 100%;
  justify-self: center;
  justify-content: space-evenly;
  display: flex;
}

.displayfields {


  justify-self: center;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column; 

}
  .displayfields-second{    flex-direction: row;   gap: 250px; margin-top: 0px;
 }
@media (min-width: 769px) {
  .hero-main {
      padding-top: 200px;
      gap: 250px;
      margin: auto;
      flex-direction: row;
  }
  .reviewCard{
    list-style-type: none;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    padding: 20px;
    margin-left:-45px;
    border-radius: 10px
}  .displayfields {
  padding-top: 150px;
  

  margin: auto;

}}


@media (max-width: 768px) {
  .hero-main {
      padding-top: 100px;
  }
   .reviewCard{
    list-style-type: none;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    padding: 10px;
    width: 100%;
    
    align-self: center;
    justify-self: center;
    border-radius: 10px

}  .displayfields{
  padding-top: 100px;
}
}
