/* General Wrapper */
.gallery-imagecard-wrapper {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

/* ImageCard Desktop */
.gallery-imagecard-container {
  flex: 0 0 calc(33.33% - 20px);
  position: relative;
  min-width: 250px;
  height: 192px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.gallery-imagecard-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: brightness(50%);
  transition: filter 0.4s ease;
  border-radius: 0;
}

.gallery-imagecard-img.hover {
  filter: brightness(75%);
}

/* Desktop Text */
.gallery-imagecard-text {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-55%, -50%);
  z-index: 2;
  color: white;
  background-color: transparent;
  text-align: center;
}

.gallery-imagecard-text p {
  margin: 0;
}

.gallery-imagecard-arrow {
  margin: 0;
  margin-top: -10px;
  font-size: 30px;
}

/* ImageCard Mobile */
.gallery-imagecard-mobile-container {
  flex: 0 0 calc(50% - 20px);
  position: relative;
  min-width: 150px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.gallery-imagecard-mobile-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: brightness(50%);
  transition: filter 0.4s ease;
  border-radius: 0;
}

.gallery-imagecard-mobile-text {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-55%, -50%);
  z-index: 2;
  color: white;
  background-color: transparent;
  text-align: center;
}
