.third-hero-main{
    gap: 0;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    background: linear-gradient(to bottom right, white, #EAF1F7);
    border-bottom: 1px solid #006389;
    padding-bottom:50px;
    min-height: 200px; 
   

}

.third-hero-div-one{
    max-width: 800px
}
.third-hero-h1{
    margin-bottom: 0; text-align: center;
    font-weight: 600;
    font-size: 40px;
    
    
}
.third-hero-p{
    text-align: left; font-weight:400 ;font-size:18px
}

@media screen and (max-width: 769px){
    .third-hero-h1{
        font-weight: 600;
        font-size: 28px;
        overflow-wrap: break-word; 
    }}