/* Container holding both the TOC and content */
.blog-container {
    display: flex;
    flex-direction: row;
    width: 95%;
    max-width: 1200px;
    margin: 0 auto;
    gap:20px
  }
  
 
    .blog-nav-card {
      background-color: white;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
      padding: 15px;
      border-radius: 10px;
      text-align: center; /* Centers inline elements like <p> */
      display: flex;
      flex-direction: column;
      align-items: center; /* Centers image */
  
  
  }
  .blog-container-col{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    gap:20px
  }
  /* TOC div; fixed width; and sticky */
  .blog-toc {
    width: 300px;
    margin-left: 20px;
    position: sticky;
    top: 200px; /* The distance from the top of the viewport where the TOC will stick */
    height: max-content; /* Ensures the div only takes up as much space as its content */
  }
  
  /* Content div; flexible width */
  .blog-content {
    flex-grow: 1;
  }
  /* Blog TOC Item Container */
.blog-toc-item {
    background: var(--light-accent); /* Assuming `colors.lightAccent` is a CSS variable */
    margin-top: 20px;
    padding: 20px 20px 40px 20px;
    border-radius: 10px;
    max-width: 760px;
    width: 90%;
    margin: auto;
    margin-bottom: 80px;
  }
  
  /* Heading in the TOC */
  .blog-toc-item h2 {
    text-align: center;
  }
  
  /* List Styles */
  .blog-toc-item ul {
    list-style: none;
    text-align: left;
    padding: 0;
  }
  
  /* List Items */
  .blog-toc-item li {
    padding: 5px 0;
    border-bottom: 1px solid black;
  }
  .fraga-second-div{  width: 90%;
    max-width: 1200px;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 30px;
    gap:50;
    text-align: left;
    display:flex}
  
  /* Anchor Links */
  .blog-toc-item a {
    text-decoration: none;
    color: var(--text-main); /* Assuming `colors.textMain` is a CSS variable */
    font-size: var(--secondary-medium-small); /* Assuming `secondaryMediumSmall` is a CSS variable */
  }
  .blog-buy-now-card-body{
    display: none;
  }
  .blog-header-card-margin{
    margin-bottom:-100px;
  }
  @media (max-width: 1200px) {
    .blog-header-card-margin{
      margin-bottom:-30px;
    }
  }
  /* Responsive design - remove TOC if screen is smaller than 800px */
  @media (max-width: 1000px) {
 

    .blog-buy-now-card-body{
    display: block;
    }
  .blog-buy-now-card-header{
    display: none;
  }

    .blog-toc {
      display: none;
    }
  }
    /* Responsive design - remove TOC if screen is smaller than 800px */
    @media (max-width: 1000px) {
      .fraga-second-div{  flex-direction: column;
        
      }
    }
  