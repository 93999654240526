

.benefits-small-item {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 28.33%;
  }
  
  @media only screen and (min-width: 769px){
    .benefits-small-item{ flex-basis: 25%; }
  }
  