.priserform-container {
    display: flex;
    flex-direction: column;
    position: sticky; /* Sticky positioning */
    top: 200px; /* Set the sticky offset from the top */
    padding: 10px;
    align-self: flex-start;
    border-radius: 5px;
    margin-top: 45px;
}
.priserform-h4{
    font-weight: 500;
    margin:  0 0 5px 0;
    padding: 0;
    text-align: left;
}

.priserform-benefits h3{ margin: 0; padding: 0;
}
.priserform-benefits{
    background-color: white;
    padding:20px;
    border-radius: 10px;
}

.priser-li::before {
    content: 	"✓	";
    color: blue; /* or whatever color you prefer */
  }
.priser-li{
    color:rgba(0, 0, 0, 0.70)
    ;
    list-style: none;

    padding-bottom: 2px;
    margin-bottom: 5px;
/*
    border-bottom: 1px solid rgba(0, 0, 0, 0.70)

*/
    ;
    width: 70%;
}
.priser-li-legalcore::before {
    content: 	"✓	";
    color: rgba(0, 0, 0, 0.50); /* or whatever color you prefer */
}

.priser-li-legalcore{
    margin-left: 10px;
    color:rgba(0, 0, 0, 0.60)
    ;
   list-style: none;
    padding-bottom: 2px;
    margin-bottom: 5px;
}
@media only screen and (max-width: 720px) {
    .priserform-container {

    }

}