.homekunder-sub-div{
    max-width:1200px;width:95%;
    margin-top: 25px;
    gap:10px;
    align-items: center;
    height: 100%;
    display:flex;align-content:center;  flex-direction:row;
    margin: 25px auto;
}
.kunder-referens-title{
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: 600;
}
@media (max-width: 1300px) {
    .homekunder-sub-div{flex-direction: column;}
    .homekunder-sub-div{gap:20px}
}


.homekunder-logo-div-sub {
    display: grid;
    margin: 0 auto;
    max-width: 1430px;
    width: 100%;
    grid-template-columns: repeat(6, 1fr); /* 6 columns in 1 row */
    gap: 10px;
}

/* Change to 3x2 layout on tablet and smaller screens */
@media (max-width: 1500px) {
    .homekunder-logo-div-sub {
        max-width: 560px;

        grid-template-columns: repeat(3, 1fr); /* 3 columns and 2 rows */
    }
}
@media (max-width: 768px) {
  .marquee-container {
    width: 100%;

  }
}
.marquee-container {
  width: 80%;
  margin: 15px auto;
  position: relative;
  overflow: hidden;
  height: 120px; /* ✅ Ensures a fixed height */
}

.marquee-item {
  padding: 0 20px;
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
}

.kund-card.grayscale {
  width: 150px; /* ✅ Fixed width */
  height: 100px; /* ✅ Fixed height */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Ensure images inside marquee don't resize unexpectedly */
.kund-card.grayscale img {
  width: 100%; /* ✅ Ensures predictable rendering */
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* Fix shifting overlay */
.marquee-container::before,
.marquee-container::after {
  content: "";
  position: absolute;
  top: 0;
  width: 50px;
  height: 100%;
  z-index: 2;
  pointer-events: none;
}

.marquee-container::before {
  left: 0;
  background: linear-gradient(to right, rgb(255, 250, 245), transparent);
}

.marquee-container::after {
  right: 0;
  background: linear-gradient(to left, rgb(255, 250, 245), transparent);
}

  
.grayscale {
    filter: grayscale(100%);
  }
  
.kund-card{
  background-color: transparent;
    border-radius: 0;
    padding: 0 15px;
    justify-self: center; /* Center individual card in its grid cell */
    align-self: center;

    margin: 10px;
}

.homekunder-main-div{

    padding-top:20px;
    padding-bottom:20px;
    flex-direction: column;
   display:flex;justify-content:center; align-items:center;   
    margin: 0 auto;
    
}


    .homekunder-trust-item{
        text-align: left;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    
        flex: 1 1 22%; /* Adjusted to fit more items per row */
        max-width: 22%; /* Ensures that the item does not grow beyond 22% of the parent width */
        box-sizing: border-box;
        margin: 10px; /* Optional: Adds some space around each box */
    
  
}
.homekunder-trust-div h3{margin: 0;margin-bottom: 5px;}
.homekunder-trust-div p{margin: 5px;}

.homekunder-trust-item h4{margin: 0;}
.homekunder-trust-item p{margin: 0; line-height: 25px;}
.homekunder-sub-trust-div{    max-width:1200px;width:100%;flex-direction: row;  
    display:flex;justify-content:space-evenly; align-content:center; flex-wrap:wrap; gap:10px;
    padding-bottom: 10px;
border-bottom:1px solid #006389;

}
.homekunder-trust-div{    max-width:1200px;width:100%;
    display:flex;justify-content:space-evenly; align-content:center; flex-wrap:wrap; }


    @media (max-width: 920px) {

            .homekunder-trust-item{
              
                flex-direction: column;
                max-width: 45%; /* Ensures that the item does not grow beyond 22% of the parent width */

                flex: 1 1 45%; /* Takes up roughly half the width on desktop */
                box-sizing: border-box;
                    
    }}
    @media (max-width: 735px) {
    
        
        .homekunder-trust-item{
          
            flex-direction: column;
            max-width: 90%; /* Ensures that the item does not grow beyond 22% of the parent width */

            flex: 1 1 90%; /* Takes up roughly half the width on desktop */
            box-sizing: border-box;
            margin: 5px;
            

                
}
.homekunder-logo-div-sub {
    max-width: 490px;

    gap: 5px;
}
.kund-card{
    background-color: #fffaf5;
      border-radius: 0;
        width: 100%;
      padding: 0px 0px;
  }
  


.homekunder-sub-trust-div{    gap:2px}

}
