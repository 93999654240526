.kontakt-container {
    padding-top: 200px;
    padding-bottom: 20px;
    display: flex;
    text-align: left;
    align-content: center;
    justify-content: center;
    margin: 0 auto;
    justify-content: center;

    
}
.kontakt-sub-container-kontakt-mobile{
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 95%;
}
.kontakt-sub-container-kontakt-desktop{
    display: flex;
    flex-direction: row;
    gap: 150px;
    max-width: 95%;
  }
  .kontakt-info-img{
    border-radius: 10px;
    width: 600px;
     box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    
  }
  .kontakt-info-header{
    margin-top: 15px;
    margin-bottom: 0;
  }
  .kontakt-info-p{
    margin-top: 5px;
    margin-bottom: 0;
  }
  .kontakt-david-bild {
    width: 200px;
    height: 200px;
    -webkit-border-radius: 200px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 200px;
    -moz-background-clip: padding;
    border-radius: 200px;
    background-clip: padding-box;
    margin: 20px 0 0 5px;
    background-size: cover;
    background-position: center 10%; /* Adjust the vertical position here */
  }
  
  .kontakt-info {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    text-align: left;
    gap:5px;
    max-width: 600px;
    flex:1;
  }
  
  .kontakt-info-2{
    display: flex;
    padding: 10px 20px;
    flex-direction: column;
    margin-top: 50px;
    text-align: left;    

    gap:5px;
    max-width: 600px;
    flex:1;
  
    border-radius: 10px;
  }

  span.line {
    display: inline-block;
  }
  
  .kontakt-link {
    color: black;
    text-decoration: none;
    word-wrap: normal;
    word-break: break-all;
    display: inline-block;
    max-width: 100%;
  }
  

  .oneline-two-link-btn{
    display: inline-block;
    padding: 15px 15px;
    border-radius: 50px;
    transition: 0.3s;
    transition: box-shadow 0.3s ease; /* Smooth transition for shadow */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25); /* Shadow on hover */

    cursor: pointer;
    text-decoration: none;
    font-size:18px;
    font-weight:500;
}
.oneline-active-link-btn-color{
    background-color: #1A76C2;
    border: 1.5px solid #1A76C2;

    color: white;
}
.kontakt-star-container{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}
.kontakt-star-container-h3{
  margin: 0;
  padding: 0;
}
  
.oneline-inactive-link-btn-color{
    color: #1A76C2;
    background-color: transparent;

    border: 1.5px solid #1A76C2;
}
.oneline-two-link-btn:hover{
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5); /* Shadow on hover */

    background-color: #ff9d33    ;
    border: 1.5px solid #ff9d33;
    color: white;
    border-color: #ff9d33;
}

@media (min-width: 1201px) and (max-width: 1300px) {

.kontakt-sub-container-kontakt-desktop{

  gap: 75px;
}

}

@media (min-width: 1101px) and (max-width: 1200px) {

  .kontakt-sub-container-kontakt-desktop{
  
    gap: 50px;
  }
  .kontakt-container {
  padding-top: 50px;

}

}


@media (min-width: 801px) and (max-width: 1100px) {
  .kontakt-info{
    
    }

  .kontakt-sub-container{
    flex-direction: column;
    margin: 0 auto;
    gap:0;

   }

  .kontakt-info-2{
    margin-top: 0;
   }
   .kontakt-container{
    padding-top: 100px;

}
}

  @media (max-width: 800px) {
  
     .kontakt-info-2{
      margin-top: 0;
     }
 
     .kontakt-info-img{
    width: 90%; 
    margin: 0 auto;
    }
     .kontakt-sub-container{
      flex-direction: column;
          margin: 0 auto;
          gap:0;

     }

  .oneline-two-link-btn{
    padding: 15px 15px;
    font-size:16px;
    font-weight:500px;
  }
        .kontakt-container{
            padding-top: 100px;

        }
    .kontakt-info {
margin-top: 0;
        padding-top: 0px;
      }
    .kontakt-david-bild {
      margin: 0 auto 20px auto;
    }
  }
  