.affaren-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  gap: 16px; /* Adjust the gap between items as needed */
  justify-content: center;
  align-content: center;
  max-width: 1200px;
  width: 95%;
}

.affaren-item-container {
  flex-basis: 45%;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15); /* Adds shadow on hover */

  text-decoration: none;
  color: black;
  display: flex;
  width: 80%;
  margin-bottom: 40px;
  flex-direction: column;
  padding: 16px 16px 16px 24px;
  background-color: #f0f4f8;
  /*border-bottom: 1px solid #1A76C2;*/
  flex: 0 1 calc(50% - 16px); /* 2 items per row with a gap */
  box-sizing: border-box; /* Ensure border is included in the element's width and height */
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out; /* Transition for box-shadow and transform */
  
}

.affaren-item-container:hover {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3); /* Adds shadow on hover */
  transform: translateY(-5px); /* Lifts the item slightly */
  text-decoration: none;
}

.affaren-item-header-div {
  display: flex;
  flex-direction: row;
  align-items: center; /* Center items vertically */
}

.affaren-item-lottie {
  margin-top: 0;
  width: 110px;
}

.affaren-item-title {
  text-align: center;
  margin: 0;
  padding: 0;
}

.affaren-item-content {
  display: flex;
  justify-content: space-between;
}

.affaren-item-text {
  margin-top: 0;
  flex: 4;
  text-align: left;
}

.affaren-item-arrow-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.affaren-item-arrow {
  font-size: 36px; /* Adjust font size */
  color: #ff9d33; /* Default color before hover */
  background-color: transparent;
  border-radius: 50%; /* Makes the background round */
  width: 40px; /* Circle size */
  height: 40px; /* Circle size */
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1; /* Ensures the arrow is vertically centered */
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out, color 0.3s ease-in-out; /* Smooth transition */
}

.affaren-item-container:hover .affaren-item-arrow {
  text-decoration: none;

  transform: translateX(20px) scale(1.4); /* Moves arrow to the right and grows slightly */
}


.affaren-item-help {
  width: 300px;
  justify-self: flex-start;
}

.affaren-item-help-title {
  margin-bottom: 5px;
  text-align: left;
}

.affaren-item-help-list {
  text-align: left;
  margin: 5px;
}

@media only screen and (max-width: 720px) {
  .affaren-item-container {
    width: 100%;
    height: auto; /* Allow height to adjust based on content */
    flex-basis: 100%;
  }
}
